import { IconSvg, iconSearch, useFabSize } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box } from '@mui/material'
import { useRouter } from 'next/router'

export function BottomSearchButton({ open }: { open: () => void }) {
  const fabIconSize = useFabSize('responsive')
  const router = useRouter()

  const focusSearch = () => {
    document.querySelector<HTMLInputElement>('[name="searchInput"]')?.focus()
  }

  return (
    <Box
      onClick={router.pathname.includes('search') ? focusSearch : open}
      aria-label='Search...'
      role='button'
      tabIndex={0}
      sx={(theme) => ({
        display: 'flex',
        bgcolor: 'background.default',
        padding: '0 20px',
        width: 'calc(100% - 170px)',
        height: fabIconSize,
        borderRadius: `999px`,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.shadows[4],
        typography: 'body1',
        color: 'text.secondary',
        marginRight: 'auto',
        cursor: 'pointer',
        border: 'none',
        '@media (max-height: 530px) and (orientation: portrait)': {
          display: 'none',
        },
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      })}
    >
      <Trans id='Search...' />
      <IconSvg src={iconSearch} sx={{ color: 'text.secondary', ml: 1 }} />
    </Box>
  )
}
