import { srcToString, StaticImport } from '@graphcommerce/image'
import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefaultProps,
  iconCustomerService,
  iconHeart,
  NavigationFab,
  MenuFabSecondaryItem,
  PlaceholderFab,
  IconSvg,
  DesktopNavItem,
  iconChevronDown,
  NavigationProvider,
  NavigationOverlay,
  useNavigationSelection,
  useMemoDeep,
  NavigationNodeType,
  useFabSize,
  NavigationNode,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Divider, Fab, Portal, useTheme } from '@mui/material'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useB2BLoginWall } from '../../lib/hooks/useB2BLoginWall'
import { useLayoutNavigationScrollTop } from '../../lib/hooks/useLayoutNavigationScrollTop'
import { useStoreData } from '../../lib/hooks/useStoreData'
import { iconQuickOrder } from '../../public/icons'
import { Autocomplete } from '../AlgoliaSearch'
import { B2BLoginWall } from '../B2BLoginWall/B2BLoginWall'
import { StoreNotice } from '../StoreNotice/StoreNotice'
import { UspsHorizontalList } from '../Usps/UspsHorizontalList'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'
import { NavigationItemImage } from './NavigationItemImage'
import blobs from './vaessen-bg-blobs.svg'
import { LayoutDefault } from './LayoutDefault'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const {
    footer,
    footerLinkList1,
    footerLinkList2,
    footerLinkList3,
    footerLinkList4,
    footerLinkList5,
    menu,
    children,
    mainMenu,
    storeNotice,
    B2BHeaderUsps,
    B2CHeaderUsps,
    ...uiProps
  } = props

  const footerLinkLists = [
    footerLinkList1,
    footerLinkList2,
    footerLinkList3,
    footerLinkList4,
    footerLinkList5,
  ]

  const theme = useTheme()
  const fabIconSize = useFabSize('responsive')

  const selection = useNavigationSelection()
  const router = useRouter()

  const { mode } = useStoreData()
  const storeB2C = mode === 'b2c'
  const headerUsps = storeB2C ? B2CHeaderUsps : B2BHeaderUsps

  const cmsMenuItems = useMemo(
    () =>
      storeB2C
        ? mainMenu?.pageLinks
            .map((item) => ({
              id: item.title.toLowerCase(),
              href: item.url,
              name: item.title,
            }))
            .filter(
              (item) =>
                ![
                  '/speciale-collecties',
                  '/special-collections',
                  '/spezielle-kollektionen',
                  '/colecciones-especiales',
                  '/collections-speciales',
                  '/collezioni-speciali',
                  '/pre-order',
                ].includes(item.href),
            ) ?? []
        : mainMenu?.pageLinks
            .map((item) => ({
              id: item.title.toLowerCase(),
              href: item.url,
              name: item.title,
            }))
            .filter(
              (item) =>
                !['/inspiration', '/inspiracion', '/ispirazione', '/inspiratie'].includes(
                  item.href,
                ),
            ) ?? [],
    [mainMenu?.pageLinks, storeB2C],
  )

  useLayoutNavigationScrollTop(selection)
  const { enableLoginWall } = useB2BLoginWall()

  const menuItems = useMemoDeep(
    () => [
      <Portal>
        <Autocomplete onSearchHitClick={() => selection.set(false)} sx={{ mb: theme.spacings.xs }}>
          <Trans id='Search...' />
        </Autocomplete>
      </Portal>,
      { id: 'home', name: <Trans id='Home' />, href: '/' },
      {
        type: NavigationNodeType.BUTTON,
        id: 'products',
        name: <Trans id='Products' />,
        childItems: [...magentoMenuToNavigation(menu, false, NavigationItemImage)],
      },

      ...cmsMenuItems,
      <Divider sx={{ my: theme.spacings.xs }} />,
      <CustomerMenuFabItem
        onClick={() => selection.set(false)}
        key='account'
        guestHref='/account/signin'
        authHref='/account'
      >
        <Trans id='Account' />
      </CustomerMenuFabItem>,
      <MenuFabSecondaryItem
        key='service'
        icon={<IconSvg src={iconCustomerService} size='medium' />}
        href='/service'
      >
        <Trans id='Customer Service' />
      </MenuFabSecondaryItem>,
      <MenuFabSecondaryItem
        key='quickorder'
        icon={<IconSvg src={iconQuickOrder} size='medium' />}
        href='/quickorder'
        sx={storeB2C ? { display: 'none' } : {}}
      >
        <Trans id='Quick order' />
      </MenuFabSecondaryItem>,
      <WishlistMenuFabItem
        onClick={() => selection.set(false)}
        key='wishlist'
        icon={<IconSvg src={iconHeart} size='medium' />}
      >
        <Trans id='Wishlist' />
      </WishlistMenuFabItem>,
    ],
    [theme.spacings.xs, menu, cmsMenuItems, storeB2C, selection],
  )

  return (
    <>
      {enableLoginWall && <B2BLoginWall />}
      {!enableLoginWall && (
        <>
          <NavigationProvider
            selection={selection}
            items={menuItems as (NavigationNode | React.ReactElement)[]}
          >
            <NavigationOverlay
              stretchColumns
              variantSm='left'
              sizeSm='full'
              justifySm='start'
              itemWidthSm='95vw'
              variantMd='left'
              sizeMd='full'
              justifyMd='start'
              mouseEvent='click'
              itemPadding='md'
              itemWidthMd='310px'
              sx={{
                marginTop: '1px',
                '& > ul > li > a, & > ul > li > [role=button]': {
                  '& span': {
                    typography: 'h4',
                  },
                },
                '.Mui-disabled.NavigationItem-item, .NavigationItem-item:hover': {
                  background: 'none',
                  color: theme.palette.primary.main,
                },
                '& .MuiButtonBase-root.column2': {
                  py: { lg: 1.2 },
                },
                '& .NavigationItem-li > .MuiButtonBase-root > svg': {
                  color: theme.palette.primary.main,
                },
              }}
            />
          </NavigationProvider>

          <LayoutDefault
            beforeHeader={
              <Box>
                {storeNotice && <StoreNotice storeNotice={storeNotice} />}
                <Box className='usp-l'>
                  <UspsHorizontalList usps={headerUsps} />
                </Box>
              </Box>
            }
            afterHeader={
              <Box className='usp-m'>
                <UspsHorizontalList usps={headerUsps} mobile />
              </Box>
            }
            sx={{
              backgroundImage: `url(${srcToString(blobs as StaticImport)})`,
              backgroundSize: `cover`,
              '& .LayoutHeader-root': {
                zIndex: 2,
              },
              gridTemplateRows: `auto auto auto auto 1fr auto`,
              '@media (min-width: 960px)': {
                gridTemplateRows: `auto auto auto 1fr auto`,
              },

              [theme.breakpoints.up('md')]: {
                '& .LayoutDefault-fabs': {
                  marginTop: `calc((${theme.appShell.headerHeightMd} + ${fabIconSize}) / 2 * -1)`,
                },
              },
            }}
            {...uiProps}
            noSticky={router.asPath.split('?')[0] === '/'}
            header={
              <>
                <Logo
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      transform: 'translateY(-10px)',
                    },
                  }}
                />
                <DesktopNavBar sx={{ flex: 1 }}>
                  <DesktopNavItem
                    onClick={() => selection.set(['products'])}
                    onKeyUp={(evt) => {
                      if (evt.key === 'Enter') selection.set(['products'])
                    }}
                    tabIndex={0}
                  >
                    <Trans id='Products' />
                    <IconSvg src={iconChevronDown} />
                  </DesktopNavItem>
                  {cmsMenuItems.map((item) => (
                    <DesktopNavItem key={item.id} href={item.href}>
                      {item.name}
                    </DesktopNavItem>
                  ))}
                </DesktopNavBar>

                <DesktopNavActions sx={{ flex: 1, gridTemplateColumns: '1fr' }}>
                  {!router.pathname.startsWith('/search') ? (
                    <Autocomplete
                      onSearchHitClick={() => selection.set(false)}
                      sx={{
                        justifySelf: 'start',
                        width: '100%',

                        [theme.breakpoints.up('md')]: {
                          width: '80%',
                          minWidth: 180,
                          maxWidth: 330,
                        },
                      }}
                    >
                      <Trans id='Search for products, categories, ...' />
                    </Autocomplete>
                  ) : (
                    <div />
                  )}
                  <Fab
                    href='/service'
                    aria-label={i18n._(/* i18n */ 'Customer Service')}
                    size='large'
                    color='inherit'
                  >
                    <IconSvg src={iconCustomerService} size='large' />
                  </Fab>
                  <Fab
                    href='/quickorder'
                    aria-label={i18n._(/* i18n */ 'Quick order')}
                    size='large'
                    color='inherit'
                    sx={storeB2C ? { display: 'none' } : {}}
                  >
                    <IconSvg src={iconQuickOrder} size='large' />
                  </Fab>
                  <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
                  <CustomerFab guestHref='/account/signin' authHref='/account' />
                  {/* The placeholder exists because the CartFab is sticky but we want to reserve the space for the <CartFab /> */}
                  <PlaceholderFab />
                </DesktopNavActions>
              </>
            }
            footer={<Footer footer={footer} footerLinkLists={footerLinkLists} />}
            cartFab={<CartFab />}
            menuFab={<NavigationFab onClick={() => selection.set([])} />}
          >
            {children}
          </LayoutDefault>
        </>
      )}
    </>
  )
}
